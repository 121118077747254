import { reducerName } from "./index";
import { createActionName } from "../../utility"



export const ORGANISATIONS = createActionName(reducerName, "GET_ORGANISATIONS")

export const SET_FIRST_ORGANISATION = createActionName(reducerName, "SET_FIRST_ORGANISATION")

export const createLoadOrganisationListAction = () => {
    return {
        type: ORGANISATIONS,
        request: {
            method: "get",
            url: "v1/manager_toolkit/organizations",
        },
        callback: (dispatch) => {
            dispatch({
                type: SET_FIRST_ORGANISATION,
            });
        }
    };
};