export const apiUrls = {
    prod: "https://appicalnow.com/api/",
    //dev: "http://localhost:3000/api/",
    dev: "https://staging.webapp.appical.net/api/",
    //dev: "https://newrelease.appicalnow.com/api/",
    staging: "https://staging.webapp.appical.net/api/",
}

const managerToolkitBaseUrls = {
    prod: "https://manager-toolkit.appicalnow.com/",
    //dev: "https://dev-manager-toolkit.appicalnow.com/", enable this again when dev env is configured
    dev: "localhost:5173/dashboard", // navigate to local manager toolkit
    staging: "https://staging-manager-toolkit.appicalnow.com/",
}

// Optionally, set REACT_APP_API_URL to override the default API URL
const apiUrl = process.env.REACT_APP_API_URL || apiUrls[process.env.REACT_APP_ENV];

export const snowplowUrls = {
    dev: "https://snowplow-test.visma.com",
    staging: "https://snowplow-test.visma.com",
    prod: "https://snowplow.visma.com",
}

export const firebaseConfig = {
    init: {
        apiKey: "AIzaSyBS1Om5hF6m-4sYifdHXBeJT2GKjNfmScM",
        authDomain: "appicalappstore.firebaseapp.com",
        databaseURL: "https://appicalappstore.firebaseio.com",
        projectId: "appicalappstore",
        storageBucket: "appicalappstore.appspot.com",
        messagingSenderId: "433336348818",
        appId: "1:433336348818:web:4df2a96e40dea5bebcf64b",
        measurementId: "G-B7JSNRLFD1",
    },
    vapidKey:
        "BPgrjVd4TPRNJKRtqIqnftC15Xhj1lGNbg_XW64JQjzH_6tfVPkF_O9cm85shre0nZgXIHuKhhLrhfzQkulP5WQ",
}

export const publicUrl = process.env.PUBLIC_URL
export const currentCompany = process.env.REACT_APP_COMPANY_NAME
export const isCurrentCompanyAppical = currentCompany === "appical"

export const essayModuleLabel = "Your answer"
export const essayModulePlaceholder = "Write something here"
export const accessibilityURL = "https://www.appical.net/hubfs/Legal%20AppicalNow/Accessibility%20Statement%20Version%201.0%20(26012023).pdf"

// Manager toolkit
const managerToolkitBaseUrl = process.env.REACT_APP_MANAGER_TOOLKIT_BASE_URL || managerToolkitBaseUrls[process.env.REACT_APP_ENV];
export const managerToolkit = {
    baseUrl: managerToolkitBaseUrl,
}

export const constants = {
    oldPlayerUrl: "https://appicalnow.com/",
    baseUrl: apiUrl,
    privacyPolicyUrl: apiUrl ? `${apiUrl.replace("/api", "")}privacy_policy` : "https://appicalnow.com/privacy_policy",
    telekomPolicyUrlEN: "https://data-privacy-onboarding.telekom.de/Privacy_policy_Appical.pdf",
    telekomPolicyUrlDE: "https://data-privacy-onboarding.telekom.de/Datenschutzbestimmungen_Appical.pdf",
    capgeminiPolicyUrl: "https://appicalnow.com/Capgemini_Appical_DataProtectionNotice.pdf",
    EPPolicyUrl: "https://appicalnow.com/EP_Appical_PrivacyStatement.pdf",
    heinekenPolicyURL: "https://appicalnow.com/Heineken_Appical_Privacy_Notice.pdf",
    userDataDeletionUrl: "/user-data-deletion",
    appicalSupportEmail: "support@appical.com",
    pageTypes: {
        text: 1,
        image: 2,
        imageText: 3,
        video: 4,
        score: 5,
        embed: 6,
        social: 7,
        essay: 8,
        photoEssay: 9,
        likert: 10,
        ar: 11,
        record: 12,
        infospots: 13,
        multipleChoice: 14,
        multipleChoiceImage: 15,
        ranking: 16,
        assign: 17,
        orderImage: 18,
        orderVideo: 19,
    },

    pageModules: {
        text: 1,
        image: 2,
        textWithImage: 3,
        video: 4,
        embed: 5,
        multipleChoice: 6,
        infoSpot: 7,
        multipleChoiceImage: 8,
        photoEssay: 9,
        essay: 10,
        likert: 11,
        score: 12,
        assign: 13,
        order: 14,
    },

    hrQuestionType: {
        parent: "parent",
        subQuestion: "subQuestion",
        completeSubQuestion: "completeSubQuestion",
        nextQuestion: "nextQuestion",
    },

    answerStatus: {
        correct: 1,
        incorrect: 2,
        neutral: 3,
    },

    userRoles: {
        admin: 1,
        editor: 2,
        viewer: 3,
        userManager: 4,
        manager: 5,
        contentUsers: 6,
        api: 7,
        newManagerToolkit: 8
    },

    courseStatuses: {
        notStarted: 1,
        inProgress: 2,
        finished: 3,
        locked: 4,
    },

    notificationTypes: {
        inform: 1,
        storyUnlocked: 2,
        welcome: 3,
        warmWelcome: 4,
        1: "inform",
        2: "storyUnlocked",
        3: "welcome",
        4: "warmWelcome",
    },

    hrFormAnswerTypes: {
        text: 1,
        date: 2,
        number: 3,
        image: 4,
        dropdown: 5,
        multipleChoice: 6,
        IBAN: 7,
        phoneNumber: 8,
        email: 9,
        file: 10,
    },

    messageTypes: {
        text: 1,
        image: 2,
        file: 3,
        info: 4,
    },

    hideManagerToolkitOption: {
        0: "progressBar",
        1: "checklist",
        2: "lastAction",
        3: "daysSinceLastAction",
        4: "essayQuestions",
        5: "performanceFilter",
        6: "courseFilter",
        7: "showLastVisit",
        8: "showLikertScale",
    },

    languages: {
        ar: {
            code: "ar",
            title: "العربية",
            rtl: true,
        },
        bn: {
            code: "bn",
            title: "বাংলা",
            rtl: false,
        },
        "zh-CN": {
            code: "zh-CN",
            title: "简体中文",
            rtl: false,
        },
        "zh-TW": {
            code: "zh-TW",
            title: "繁體中文",
            rtl: false,
        },
        cs: {
            code: "cs",
            title: "čeština",
            rtl: false,
        },
        da: {
            code: "da",
            title: "Dansk",
            rtl: false,
        },
        nl: {
            code: "nl",
            title: "Nederlands",
            rtl: false,
        },
        en: {
            code: "en",
            title: "English",
            rtl: false,
        },
        fi: {
            code: "fi",
            title: "Suomi",
            rtl: false,
        },
        fr: {
            code: "fr",
            title: "Français",
            rtl: false,
        },
        fy: {
            code: "fy",
            title: "Frysk",
            rtl: false,
        },
        de: {
            code: "de",
            title: "Deutsch",
            rtl: false,
        },
        he: {
            code: "he",
            title: "עברית",
            rtl: true,
        },
        hi: {
            code: "hi",
            title: "हिन्दी",
            rtl: false,
        },
        hr: {
            code: "hr",
            title: "Hrvatski",
            rtl: false,
        },
        hu: {
            code: "hu",
            title: "Magyar",
            rtl: false,
        },
        id: {
            code: "id",
            title: "Bahasa Indonesia",
            rtl: false,
        },
        it: {
            code: "it",
            title: "Italiano",
            rtl: false,
        },
        ja: {
            code: "ja",
            title: "日本語",
            rtl: false,
        },
        ko: {
            code: "ko",
            title: "한국인",
            rtl: false,
        },
        lv: {
            code: "lv",
            title: "Latviski",
            rtl: false,
        },
        ms: {
            code: "ms",
            title: "بهاس ملايو",
            rtl: false,
        },
        no: {
            code: "no",
            title: "Norsk",
            rtl: false,
        },
        pl: {
            code: "pl",
            title: "Polski",
            rtl: false,
        },
        pt: {
            code: "pt",
            title: "Português",
            rtl: false,
        },
        ro: {
            code: "ro",
            title: "Română",
            rtl: false,
        },
        ru: {
            code: "ru",
            title: "Русский",
            rtl: false,
        },
        sk: {
            code: "sk",
            title: "Slovenčina",
            rtl: false,
        },
        sr: {
            code: "sr",
            title: "Srpski",
            rtl: false,
        },
        es: {
            code: "es",
            title: "Español",
            rtl: false,
        },
        sv: {
            code: "sv",
            title: "Svenska",
            rtl: false,
        },
        th: {
            code: "th",
            title: "ภาษาไทย",
            rtl: false,
        },
        tr: {
            code: "tr",
            title: "Türkçe",
            rtl: false,
        },
        vi: {
            code: "vi",
            title: "Tiếng Việt Nam",
            rtl: false,
        },
    },
    dashboardType: {
        onBoarding: 0,
        offBoarding: 1,
        simpleDashboard: 2,
    },
    managerToolkitType: {
        basic: 0,
        advanced: 1,
        disabled: 2,
    },
    forwardDialogStates: {
        close: -1,
        openConfirmUser: 0,
        selectManger: 1,
        createManger: 2,
    },
    directLinksType: {
        glossary: "Glossary",
        people: "People",
        faq: "FAQ",
    },
    categoryTypeNumbers: {
        glossary: 1,
        people: 2,
        faq: 3,
        files: 4,
    },
    tagsType: {
        page: "pageTag",
        glossary: "glossaryTag",
        peopleFinder: "pfTag",
        faq: "faqTag",
    },

    leaderboard: {
        position: {
            first: "1",
            second: "2",
            third: "3",
        },
    },
    searchSectionType: {
        people: "people",
        glossaries: "glossaries",
        faqs: "faqs",
        pages: "pages",
        chapters: "chapters",
        checklists: "checklists",
        stories: "stories",
    },

    scorePageCriteria: {
        percentage: 0,
        points: 1,
    },
}

const pusherKeys = {
    prod: process.env.REACT_APP_PUSHER_KEY || "08a2c06d279ddeaabc94",
    dev: process.env.REACT_APP_PUSHER_KEY || "fc88d7c0d4c16f01ce9f",
    staging: "fc88d7c0d4c16f01ce9f",
}
export const cookieURLS = {
    chromeCookiePolicyURL: "https://support.google.com/chrome/answer/95647?hl=en",
    safariCookiePolicyURL: "https://www.apple.com/legal/privacy/en-ww/cookies/",
    firefoxCookiePolicyURL: "https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop",
    internetExplorerPolicyURL: "https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d",
}

export const pusherConfig = {
    key: pusherKeys[process.env.REACT_APP_ENV] || pusherKeys.dev,
    cluster: "eu",
    authEndpoint: "chat/pusher/auth",
}

export default constants
