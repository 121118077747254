import get from "lodash/get"
import find from "lodash/find"
import { createSelector } from "reselect"

import reducerRegistery from "../../ReducerRegistery"
import { createActionName, createReducer } from "../../utility"
import { ERROR, LOADED, LOADING } from "../../middleware/actions"
import { LOGOUT } from "../authentication"
import { selectCoursesByOrganisation, selectSelectedCourse } from "../courses"
import constants from "../../../constants"
import { createMarkTutorialAsSeenAction } from "../messages"

// Required variables
const initialState = {
    organisations: [],
    isLoading: false,
    loadingError: null,
    settingOrganisation: false,
    hasFetched: false,
    currentOrganisation: null
}
export const reducerName = "organisations"

// Selectors
export const selectOrganisations = (state) => {
    const organisations = get(state, `${reducerName}.organisations`)
    const coursesByOrganisation = selectCoursesByOrganisation(state)

    return organisations.map((organisation) => {
        const coursesForOrganisation = get(coursesByOrganisation, organisation.id, [])

        if (
            coursesForOrganisation.length === 0
            && organisation.role === constants.userRoles.manager
        ) {
            coursesForOrganisation.push({
                id: -1,
                customer_name: organisation.name,
                customer_id: organisation.id,
                available: true,
            })
        }

        return {
            ...organisation,
            courses: coursesForOrganisation,
        }
    })
}

export const selectedOrganisation = (state) => {
    const organisations = get(state, `${reducerName}.organisations`)
    const course = selectSelectedCourse(state)

    if (!course || (course && course.id === -1)) return find(organisations, { selected: true })

    return find(organisations, { id: course.customer_id }) || {}
}

export const selectIsLoading = state => get(state, `${reducerName}.isLoading`)
export const selectLoadingError = state => get(state, `${reducerName}.loadingError`)
export const selectHasFetched = state => get(state, `${reducerName}.hasFetched`)
export const selectIsSettingOrganisation = state => get(state, `${reducerName}.settingOrganisation`)
export const selectCurrentOrganisation = state => get(state, `${reducerName}.currentOrganisation`)
export const selectHideManagerToolkitOptions = createSelector(
    selectedOrganisation,
    (data) => {
        const { hideManagerToolkitOption } = constants
        const hideManagerToolkitOptions = get(data, "hide_manager_toolkit_options")
        const hide = {
            progressBar: false,
            checklist: false,
            lastAction: false,
            daysSinceLastAction: false,
            essayQuestions: false,
            performanceFilter: false,
            courseFilter: false,
            showLastVisit: false,
            showLikert: false,
        }
        if (hideManagerToolkitOptions) {
            for (let index = 0; index < hideManagerToolkitOptions.length; index += 1) {
                let key = hideManagerToolkitOptions[index]
                key = hideManagerToolkitOption[key]
                hide[key] = true
            }
        }
        return hide
    },
)

export const selectIsManagerForwardingActive = createSelector(
    selectedOrganisation,
    (data) => {
        const managerForwarding = get(data, "manager_forwarding")
        const managerToolkitVersion = get(data, "manager_toolkit_version")
        const { managerToolkitType: { advanced } } = constants
        const isAdvancedManagerToolkitActive = managerToolkitVersion === advanced
        return isAdvancedManagerToolkitActive && managerForwarding
    },
)

export const selectManagerToolkitType = createSelector(
    selectedOrganisation,
    data => get(data, "manager_toolkit_version", 0),
)

export const selectShowHRForms = createSelector(
    selectedOrganisation,
    data => get(data, "show_hr_forms", true),
)

export const selectMessageTutorial = createSelector(
    selectedOrganisation,
    data => get(data, "show_message_tutorial", true),
)

// Actions
export const ORGANISATIONS = createActionName(reducerName, "ORGANISATIONS")
export const SET_ORGANISATION = createActionName(reducerName, "SET_ORGANISATION")

// Action creators
export function createLoadingAction() {
    return {
        type: ORGANISATIONS,
        request: {
            method: "get",
            url: "organizations",
        },
        callback: (dispatch, getState, payload) => {
            const result = get(payload, "result")
            const course = selectSelectedCourse(getState())
            const currentOrganisation = selectCurrentOrganisation(getState())
            let data
            if (course && course.id !== -1) {
                data = find(result, { id: course.customer_id })
            } else if (currentOrganisation && currentOrganisation.id) {
                data = find(result, { id: currentOrganisation.id })
            } else {
                data = result[0]
            }
            const showMessageTutorial = get(data, "show_message_tutorial", true)
            dispatch(createMarkTutorialAsSeenAction(!showMessageTutorial))
        },
    }
}

export function createGetSelectOrgData(callback) {
    return {
        type: ORGANISATIONS,
        request: {
            method: "get",
            url: "organizations",
        },
        callback: (dispatch, getState, payload) => {
            const result = get(payload, "result")
            const course = selectSelectedCourse(getState())
            const currentOrganisation = selectCurrentOrganisation(getState())
            if (typeof callback === "function") {
                if (course && course.id !== -1) {
                    callback(find(result, { id: course.customer_id }), null)
                } else if (currentOrganisation && currentOrganisation.id) {
                    callback(find(result, { id: currentOrganisation.id }), null)
                } else {
                    callback(result[0], null)
                }
            }
        },
    }
}

export function createSetOrganisationAction(newOrganisation, sendRequest) {
    return (dispatch) => {
        if (sendRequest) {
            dispatch({
                type: SET_ORGANISATION,
                request: {
                    method: "post",
                    url: `set_organization?id=${newOrganisation.id}`,
                },
                payload: newOrganisation,
            })
        } else {
            dispatch({
                type: SET_ORGANISATION,
                payload: newOrganisation,
            })
        }
    }
}

// Reducer
const reducers = {
    [ORGANISATIONS + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },
    [ORGANISATIONS + LOADED](state, payload) {
        return {
            ...state,
            isLoading: false,
            loadingError: null,
            organisations: payload.result,
            hasFetched: true,
        }
    },
    [ORGANISATIONS + ERROR](state, payload) {
        return { ...state, isLoading: false, loadingError: payload.result }
    },

    [SET_ORGANISATION](state, organisation) {
        return { ...state, currentOrganisation: organisation }
    },

    [SET_ORGANISATION + LOADING](state) {
        return { ...state, settingOrganisation: true, loadingError: null }
    },
    [SET_ORGANISATION + LOADED](state, payload) {
        const organisation = payload.originalPayload
        return { ...state, settingOrganisation: false, currentOrganisation: organisation }
    },
    [SET_ORGANISATION + ERROR](state, payload) {
        return { ...state, settingOrganisation: false, loadingError: payload.result }
    },

    // Reset reducers
    [LOGOUT + LOADED]() {
        return initialState
    },
}

export const reducer = createReducer(reducers, initialState)

reducerRegistery.register(reducerName, reducer)
