import { LOGOUT } from "../authentication"
import { createReducer } from "../../utility"
import reducerRegistery from "../../ReducerRegistery"
import { ORGANISATIONS, SET_FIRST_ORGANISATION } from "./actionCreatores"
import { ERROR, LOADED, LOADING } from "../../middleware/actions"

// define the initial state of the reducer
const initialState = {
    organisationsV1: [],
    firstOrganisation: {
        id: null,
        name: '',
        status: '',
        role_names: [''],
        can_access_new_hire: false,
        can_access_editor: false,
        can_access_old_manager_toolkit: false,
        can_access_new_manager_toolkit: false,
    },
    isLoading: false,
    loadingError: null,
    hasFetched: false,
}


export const reducerName = 'organisations_v1'

// Selectors
export * from "./selectors"


// Actions
export * from "./actionCreatores"


// Reducer
const reducers = {
    [ORGANISATIONS + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null };
    },
    [ORGANISATIONS + LOADED](state, payload) {
        return {
            ...state,
            isLoading: false,
            loadingError: null,
            organisationsV1: payload.result.data,
            hasFetched: true,
        };
    },
    [SET_FIRST_ORGANISATION](state) {
        return { ...state, firstOrganisation: state.organisationsV1[0] || {} };
    },
    [SET_FIRST_ORGANISATION + ERROR](state, payload) {
        return { ...state, loadingError: payload.result }
    },
    [LOGOUT + LOADED]: () => initialState
};

export const reducer = createReducer(reducers, initialState)

reducerRegistery.register(reducerName, reducer)